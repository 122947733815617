import * as React from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import {StaticImage} from "gatsby-plugin-image";
import Mail from "../assets/Mail.svg";
import Phone from "../assets/Phone.svg";
import Arrow from "../assets/Arrow1.svg"
import "../components/contactform/contactform.css";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import axios from "axios";
import qs from "qs";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3"

const SITE_KEY = "6Lfp2QofAAAAAFvOvI552ulSuxfo9sqbGMD8Bima";


const Kontakt = () => {
    const [token, setToken] = useState()

    const [submitStatus, setSubmitStatus] = useState(false);
    const [getError, setError] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    const submitData = (e) => {

        e.preventDefault();

        let post = [];

        e.target.querySelectorAll(`input, textarea`).forEach(item => {

            if (item.value == "") {
                setError(true);
                return;
            }

            post[item.name] = item.value;
            setError(false);

        })

        if (!getError && post) {

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(token => {
                    post["g-recaptcha-response"] = token;
                });
            });

            axios.post(`https://djpiotrszymanski.pl/api/`, qs.stringify(post))
                .then(() => {
                    setSubmitStatus(true)
                })
                .catch(() => {
                    setSubmitStatus(false)
                })
        }

    }

    return (
        <Layout>
            <Helmet>
                <title>Kontakt, zapytanie o termin w Warszawie - DJ Piotr Szymański</title>
                <meta name="description"
                      content="Jesteście zdecydowani lub macie dodatkowe pytania? Jeśli tak to napiszcie do mnie, zadzwońcie z chęcią odpowiem, podpowiem!"/>
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>
            <Hero className="">
                <StaticImage
                    src="../images/hero-kontakt.png"
                    alt="Kontakt"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title subpage">
                    <h1>Masz pytania? Chcesz zarezerwować termin?</h1>
                    <h3 className="line-before">skontaktuj się z DJ Piotr Szymański</h3>
                </div>
            </Hero>
            <section className="kontakt-wrapper">
                <div className="contact-data">
                    <div className='contact-data-name'>
                        <h2>Piotr Szymański</h2>
                        <h3>DJ / Konferansjer</h3>
                    </div>
                    <div className="contsct-data-icons">
                        <Mail/> <Phone/>
                    </div>
                    <div className="color-second contact-data-nums">
                        <div>Tel.: <a href={"tel:+48792530430"} className={"color-second"}>+48 792 530 430</a></div>
                        <div>E-mail: <a href={"mailto:hello@djpiotrszymanski.pl"}
                                        className={"color-second"}>hello@djpiotrszymanski.pl</a>
                        </div>
                    </div>
                </div>
                <div className="kontakt-form">

                    {
                        !submitStatus && <form className="form" method={"POST"} onSubmit={submitData}>
                            <div className="form-item">
                                <input id="email" name="email" type="email" placeholder="E-mail" required/>
                            </div>
                            <div className="form-item">
                                <input id="phone" name="phone" type="phone" placeholder="Numer telefonu"
                                       defaultValue={"+48"}
                                       maxLength={12} required/>
                            </div>
                            <div className="form-item">
                                <input id="date" name="date" type="date" placeholder="Data w formacie dd-mm-rrrr"
                                       required/>
                            </div>
                            <div className="form-item">
                                <input id="place" name="place" type="text" placeholder="Miejsce" required/>
                            </div>
                            <div className="form-item">
                                <input id="guests" name="guests" type="number" placeholder="Liczba gości" required
                                       min={1}/>
                            </div>
                            <div className="form-item">
                    <textarea id="message" name="message" type="text" placeholder="Wiadomości" rows="4" required
                              minLength={15}/>
                            </div>
                            <div className="submit">
                                <input id="submit" type="submit" value="wyślij"/><Arrow/>
                            </div>
                        </form>
                    }
                    {
                        submitStatus && <div>Wiadomość została wysłana, postaram się szybko odpowiedzieć.</div>
                    }
                </div>
            </section>
        </Layout>
    )
}

export default Kontakt;
